@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;700&display=swap');

:root {
  --black: #1c1c1c;
  --gray: #7E7E7E;
  --gray-light: #E4E4E4;
  --red: #b30000;
  --font-size-base: 1rem;
  --font-size-md: clamp(1.25rem, 0.61vw + 1.1rem, 1.58rem);
  --font-size-lg: clamp(1.56rem, 1vw + 1.31rem, 2.11rem);
  --font-size-xl: clamp(2.44rem, 2.38vw + 1.85rem, 3.75rem);
  --border-radius: 10px;
}

body {
  font-family: 'Poppins', sans-serif;
  color: var(--black);
  font-size: var(--font-size-base);
  background-color: #FFFFFF;
  margin: 0;
}
a {
  color: #e06666;
}

.glitched{
  animation: glitch 1s linear infinite;
  font-family: 'Fira-Mono';
}

@keyframes glitch{
  2%,64%{
    transform: translate(2px,0) skew(0deg);
  }
  4%,60%{
    transform: translate(-2px,0) skew(0deg);
  }
  62%{
    transform: translate(0,0) skew(5deg); 
  }
}

.glitched:before,
.glitched:after{
  content: attr(title);
  position: absolute;
  left: 0;
}

.glitched:before{
  animation: glitchTop 1s linear infinite;
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

@keyframes glitchTop{
  2%,64%{
    transform: translate(2px,-2px);
  }
  4%,60%{
    transform: translate(-2px,2px);
  }
  62%{
    transform: translate(13px,-1px) skew(-13deg); 
  }
}

.glitched:after{
  animation: glitchBotom 1.5s linear infinite;
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

@keyframes glitchBotom{
  2%,64%{
    transform: translate(-2px,0);
  }
  4%,60%{
    transform: translate(-2px,0);
  }
  62%{
    transform: translate(-22px,5px) skew(21deg); 
  }
}

.error {
	margin-bottom: 1rem;
	font-size: 2rem;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 0.075em;
	color: #C94D4D;
	animation: pulse 1s infinite alternate;
	position: relative;
}

.error::before {
	content: '';
	width: 0.75rem;
	height: 50vh;
	margin-bottom: 0.75em;
	position: absolute;
	left: 50%;
	bottom: 100%;
	transform: translateX(-50%);
	background: linear-gradient(to bottom,
		rgba(255, 255, 255, 0.1) 60%,
		transparent 100%
	);
}

@keyframes pulse {
	from {
		opacity: 0.5;
	}
	50% {
		opacity: 0.5;
	}
}
